@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-Thin.eot');
    src: local('Brandon Grotesque Thin'), local('BrandonGrotesque-Thin'),
        url('../fonts/BrandonGrotesque-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BrandonGrotesque-Thin.woff2') format('woff2'),
        url('../fonts/BrandonGrotesque-Thin.woff') format('woff'),
        url('../fonts/BrandonGrotesque-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-Medium.eot');
    src: local('Brandon Grotesque Medium'), local('BrandonGrotesque-Medium'),
        url('../fonts/BrandonGrotesque-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BrandonGrotesque-Medium.woff2') format('woff2'),
        url('../fonts/BrandonGrotesque-Medium.woff') format('woff'),
        url('../fonts/BrandonGrotesque-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-BoldItalic.eot');
    src: local('Brandon Grotesque Bold Italic'), local('BrandonGrotesque-BoldItalic'),
        url('../fonts/BrandonGrotesque-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BrandonGrotesque-BoldItalic.woff2') format('woff2'),
        url('../fonts/BrandonGrotesque-BoldItalic.woff') format('woff'),
        url('../fonts/BrandonGrotesque-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-BlackItalic.eot');
    src: local('Brandon Grotesque Black Italic'), local('BrandonGrotesque-BlackItalic'),
        url('../fonts/BrandonGrotesque-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BrandonGrotesque-BlackItalic.woff2') format('woff2'),
        url('../fonts/BrandonGrotesque-BlackItalic.woff') format('woff'),
        url('../fonts/BrandonGrotesque-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-Regular.eot');
    src: local('Brandon Grotesque Regular'), local('BrandonGrotesque-Regular'),
        url('../fonts/BrandonGrotesque-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BrandonGrotesque-Regular.woff2') format('woff2'),
        url('../fonts/BrandonGrotesque-Regular.woff') format('woff'),
        url('../fonts/BrandonGrotesque-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-RegularItalic.eot');
    src: local('Brandon Grotesque Regular Italic'), local('BrandonGrotesque-RegularItalic'),
        url('../fonts/BrandonGrotesque-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BrandonGrotesque-RegularItalic.woff2') format('woff2'),
        url('../fonts/BrandonGrotesque-RegularItalic.woff') format('woff'),
        url('../fonts/BrandonGrotesque-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-Bold.eot');
    src: local('Brandon Grotesque Bold'), local('BrandonGrotesque-Bold'),
        url('../fonts/BrandonGrotesque-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BrandonGrotesque-Bold.woff2') format('woff2'),
        url('../fonts/BrandonGrotesque-Bold.woff') format('woff'),
        url('../fonts/BrandonGrotesque-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-MediumItalic.eot');
    src: local('Brandon Grotesque Medium Italic'), local('BrandonGrotesque-MediumItalic'),
        url('../fonts/BrandonGrotesque-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BrandonGrotesque-MediumItalic.woff2') format('woff2'),
        url('../fonts/BrandonGrotesque-MediumItalic.woff') format('woff'),
        url('../fonts/BrandonGrotesque-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-ThinItalic.eot');
    src: local('Brandon Grotesque Thin Italic'), local('BrandonGrotesque-ThinItalic'),
        url('../fonts/BrandonGrotesque-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BrandonGrotesque-ThinItalic.woff2') format('woff2'),
        url('../fonts/BrandonGrotesque-ThinItalic.woff') format('woff'),
        url('../fonts/BrandonGrotesque-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-LightItalic.eot');
    src: local('Brandon Grotesque Light Italic'), local('BrandonGrotesque-LightItalic'),
        url('../fonts/BrandonGrotesque-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BrandonGrotesque-LightItalic.woff2') format('woff2'),
        url('../fonts/BrandonGrotesque-LightItalic.woff') format('woff'),
        url('../fonts/BrandonGrotesque-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-Light.eot');
    src: local('Brandon Grotesque Light'), local('BrandonGrotesque-Light'),
        url('../fonts/BrandonGrotesque-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BrandonGrotesque-Light.woff2') format('woff2'),
        url('../fonts/BrandonGrotesque-Light.woff') format('woff'),
        url('../fonts/BrandonGrotesque-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-Black.eot');
    src: local('Brandon Grotesque Black'), local('BrandonGrotesque-Black'),
        url('../fonts/BrandonGrotesque-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BrandonGrotesque-Black.woff2') format('woff2'),
        url('../fonts/BrandonGrotesque-Black.woff') format('woff'),
        url('../fonts/BrandonGrotesque-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    font-family: 'Brandon Grotesque';
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

a:link {
	text-decoration: none !important;
}

a:hover {
	text-decoration: underline !important;
}



/*
ol,
ul {
    list-style: none;
}
*/
blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* responsive */
html {
    font-size: 18px;
}

@media (max-width: 800px) {
    html {
        font-size: 18px;
    }
}

@media (max-width: 500px) {
    html {
        font-size: 16px;
    }
}

/* common styles */
body {
    background: #f1f1f1;
    color: #000;
}

.page {
    background: #fff;
    width: 920px;
    margin: 0 auto;
    padding: 20px 20px 0 20px;
    overflow: hidden;
}

.font-container {
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 40px;
    line-height: 1.3;
    white-space: nowrap;
    padding-bottom: 5px;
}

h1 {
    position: relative;
    background: #444;
    font-size: 32px;
    color: #fff;
    padding: 1rem 2rem;
    margin: 0 0 1rem 0;
    font-family: 'Brandon Grotesque';
    font-weight: 500;
    font-style: normal;
}

.letters {}

h2 {
    font-size: 2rem;
    letter-spacing: 0.187rem;
    line-height: 1.2;
    margin: 1rem 0 1rem 0;
    font-family: 'Brandon Grotesque';
    font-weight: 500;
    font-style: normal;
}

h5 {
    font-size: 1rem;
    letter-spacing: 0.187rem;
    line-height: 1.2;
    margin: 0 0 1rem 0;
    font-family: 'Brandon Grotesque';
    font-weight: 300;
    font-style: normal;
}

p {
    line-height: 2;
    margin-bottom: 1.2rem;
    font-weight: 300;
    font-size: 1rem;
    font-style: normal;
    font-family: 'Brandon Grotesque';
    font-weight: 300;
    font-style: normal;
}

s .logo {
    font-family: 'Brandon Grotesque';
    font-weight: 300;
    font-style: normal;
}

.container {
    width: 100%
}

.container>.row {
    padding-top: 2rem;
    padding-bottom: 2rem
}

.logo {
    width: 10.5rem;
    font-family: 'Brandon Grotesque';
    font-weight: 200;
    font-style: normal;
    color: #FFFFFF;
}

.logo-top {
    font-size: 1.875rem;
    border-bottom: 2px solid white;
    text-align: center;
    letter-spacing: 0.3125rem;
    line-height: 1;
    margin-bottom: 0px;
}

.logo-bottom {
    font-size: 0.625rem;
    text-align: center;
    letter-spacing: 0.1875rem;
    margin-top: 0.3125rem;
    line-height: 1;
    margin-bottom: 0px;
}

.over-strike-thru {
    text-align: center;
    overflow: hidden
}

.over-strike-thru h2 {
    display: inline-block;
    position: relative;
    padding: 0 1.2rem
}

.over-strike-thru h2:before,
.over-strike-thru h2:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    display: inline-block;
    width: 5000px;
    border-top: 1px solid #ddd
}

.over-strike-thru h2:before {
    left: inherit;
    right: 100%
}

.over-strike-thru h2 span.styled {
    font-weight: 500;
    color: #E46363
}

.styled {
    font-weight: 500;
    color: #E46363
}

.banner {
    padding: 100px 0;
    width: 100%;
    background-size: cover !important;
    position: relative;
    background: url(../images/banner.jpg) no-repeat left center;
}

.banner-row {
    position: relative;
    padding-left: 1.2rem;
    padding-right: 1.6rem;
    text-align: right;
    z-index: 3;
}

.banner-row h2 {
    text-transform: uppercase;
    color: white;
    font-size: 3.375rem
}

.banner:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: #000;
    opacity: .4;
}

.breadcrumb {
    text-transform: uppercase;
    border-top: 1px solid #fff;
    display: inline-block;
    color: #fff;
    padding-top: 0.375rem;
    margin-bottom: 0.8rem;
    letter-spacing: 0.3125rem;
    font-size: 0.8rem;
    background-color: transparent !important;
    border-radius: 0px !important;
}

.breadcrumb .arrow {
    position: relative;
    top: -2px;
    padding: 0 0.25rem 0 0.375rem;
}

.breadcrumb a {
    color: #fff;
}

.imglinkslinks {
    font-size: 2rem;
    font-family: 'Brandon Grotesque';
    text-decoration: none;
}

.imglinks-img {
    width: 400px;
    height: 400px;
}

.imglinks:hover::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: #000;
    opacity: .4;
}

.carousel-old-fix {
    height: 720px;
}

.carousel-inner-old-fix>.item>img {
    height: 720px;
    object-fit: contain;
}

.achievement {
    margin: 0;
    padding-left: 1.2rem;
}

.achievement li {
    position: relative;
    list-style-type: none;
    padding-left: 2.5rem;
    margin-bottom: 0.5rem;
    line-height: 2rem;
}

.achievement li:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: -2px;
    width: 5px;
    height: 11px;
    border-width: 0 2px 2px 0;
    border-style: solid;
    border-color: #00a8a8;
    transform-origin: bottom left;
    transform: rotate(45deg);
}

.shogilist {
    margin: 0;
    padding-left: 3rem;
}

.shogilist li {
    position: relative;
    list-style-type: none;
    padding-left: 2.5rem;
    margin-bottom: 0.5rem;
    line-height: 2rem;
}

.shogilist li:before {
    content: '\2617';
    display: block;
    position: absolute;
    left: 1rem;
    top: -2px;
    width: 5px;
    height: 11px;
    color: #E46363;
    transform-origin: bottom left;
    transform: rotate(90deg);
}


.quotationlist {
    margin: 0;
    padding-left: 1.2rem;
}

.quotationlist li {
    position: relative;
    list-style-type: none;
    padding-left: 2.5rem;
    margin-bottom: 0.5rem;
    line-height: 2rem;
}

.quotationlist li:before {
    content: '\276F';
    display: block;
    position: absolute;
    left: 0;
    top: -2px;
    width: 5px;
    height: 11px;
    color: #E46363;
}

.checklist {
    margin: 0;
    padding-left: 1.2rem;
}

.checklist li {
    position: relative;
    list-style-type: none;
    padding-left: 2.5rem;
    margin-bottom: 0.5rem;
    line-height: 2rem;
}

.checklist li:before {
    content: '\2611';
    display: block;
    position: absolute;
    left: 0;
    top: -2px;
    width: 5px;
    height: 11px;
    color: #38be4e;
}


#menuCollapse {
    display: none;
    margin-top: 35px;
    width: 100vw;
    height: calc(100vh - 35px);
    background-color: #333333;
}

#menuCollapse, #menuCollapse h1, #menuCollapse h2, #menuCollapse h2 a {
    color: white;
    padding-bottom: 2rem;
}

.en-construction {}

.en-construction::after {
    content: " En construction";
    font-style: italic;
    color: #000090;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: .3rem;
}

.margin-top-spacer {
    padding-top:2rem !important;
}


/** responsive **/
@media (max-width: 959px) {
    .page {
        margin: 0;
        width: auto;
    }
}
